document.addEventListener('turbo:load', function(e) {

  /* --------------------------------------------------
                Destroy
  -------------------------------------------------- */
  pgNotesDestroy = function(id){
    let note = document.getElementById('note-' + id);

    note.remove();
  }
});
