import Chart from 'chart.js';
document.addEventListener('turbo:load', function(e) {

  /* --------------------------------------------------
                Chart Init
  -------------------------------------------------- */
  const pgDashboardChartInit = function(){
    let chartElement = document.getElementById('chartjs');

    if (chartElement) {
      new Chart(chartElement.getContext('2d'), {
        type: 'line',
        data: {
          labels: chartData["labels"],
          datasets: [{
            label: 'Downloads',
            backgroundColor: 'rgba(0, 0, 0, 0)',
            pointBackgroundColor: '#f56565',
            borderColor: '#f56565',
            data: chartData["job_transaction_charges_digital"]
          },{
            label: 'Print Requests',
            backgroundColor: 'rgba(0, 0, 0, 0)',
            pointBackgroundColor: '#48bb78',
            borderColor: '#48bb78',
            data: chartData["job_transaction_charges_print"]
          },{
            label: 'Print Deposits',
            backgroundColor: 'rgba(0, 0, 0, 0)',
            pointBackgroundColor: '#4299e1',
            borderColor: '#4299e1',
            data: chartData["job_transaction_deposits_print"]
          },{
            label: 'User',
            backgroundColor: 'rgba(0, 0, 0, 0)',
            pointBackgroundColor: '#9f7aea',
            borderColor: '#9f7aea',
            data: chartData["users"]
          }]
        },
        options: {
          maintainAspectRatio: false,
          legend: {
            align: 'end',
            labels: {
              usePointStyle: true,
              boxWidth: 8
            }
          }
        }
      });
    }
  }

  /* --------------------------------------------------
                Functions
  -------------------------------------------------- */
  pgDashboardChartInit();
});
