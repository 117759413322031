document.addEventListener('turbo:load', function(e) {

  /* --------------------------------------------------
                Init
  -------------------------------------------------- */
  const pgHomeInit = function(){
    pgHomeIntercomInit();
  }

  /* --------------------------------------------------
                Intercom
  -------------------------------------------------- */
  const pgHomeIntercomInit = function () {
    let intercomLaunch = document.getElementById('home-intercom-launch');

    // Click
    if (intercomLaunch) {
      let intercomLaunchMessage = intercomLaunch.dataset.js;
      intercomLaunch.addEventListener('click', (e) => {
        Intercom('showMessages');
        Intercom('showNewMessage', intercomLaunchMessage);
        e.preventDefault();
      });
    }
  }

  /* --------------------------------------------------
                Functions
  -------------------------------------------------- */
  pgHomeInit();
});
