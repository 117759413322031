document.addEventListener('turbo:load', function(e) {

  /* --------------------------------------------------
                Create
  -------------------------------------------------- */
  pgNotesCreate = function(partial){
    let notesIndex = document.getElementById('notes-index');
    let notesModal = document.getElementById('modal-pages-notes-new');
    let notesModalContent = notesModal.querySelector('[name="note[content]"]');
    let notesModalClose = '[data-js="modal-close"]';

    notesIndex.insertAdjacentHTML('afterbegin', unescape(partial));
    notesModal.querySelector(notesModalClose).click();
    notesModalContent.value = '';
    pgNotesInit();
  }
});
