import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "category" ]
  static values = {
    url: String
  }

  connect() {
    this.csrfToken =  document.querySelector('meta[name="csrf-token"]').content;
  }

  updatePaymentIntent() {
    /*let items = this.categoryTargets.filter(function(el) { return el.checked })
    fetch(this.urlValue, {
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "X-CSRF-Token": this.csrfToken
      },
      method: "POST",
      body: JSON.stringify({ items: items.map(i=> i.value) })
    }).then((response) => {
        return response.json()
      })
      .then((data) => {
        document.querySelector('form#new_job_transaction').removeEventListener('submit', window.stripeFormSubmit)
        if (!!data.clientSecret) {
          this.initStripe(data.clientSecret)
        }
      })
      .catch((error) => {
        console.log(error)
      })*/
  }

  initStripe(stripeIntentSecret) {
    let stripeElementsEl = document.getElementById('vendors-stripe-elements');

    if (stripeElementsEl) {
      let stripeElements = stripe.elements({clientSecret: stripeIntentSecret});
      let stripeCard = stripeElements.create('payment', { style: { base: { fontSize: '16px', color: '#2d3748' } } });
      let stripeElementsErrors = document.getElementById('vendors-stripe-elements-errors');
      let stripeForm = stripeElementsEl.closest('form');
      let stripeFormSubmit = stripeForm.querySelector('button[type="submit"]');

      stripeCard.mount('#' + stripeElementsEl.id);
      window.stripeFormSubmit = function (e) {
        e.preventDefault();
        stripeFormSubmit.disabled = true;
        stripeElements.submit().then(function (result) {
          console.log(result)
          if (result.error) {
            stripeFormSubmit.disabled = false;
            stripeElementsErrors.textContent = result.error.message;
            stripeElementsErrors.style.display = 'block';
          } else {
            //stripeTokenHandler(result.token);
            stripeElementsErrors.style.display = 'none';
            stripe.confirmPayment({
              clientSecret: stripeIntentSecret,
              elements: stripeElements,
              confirmParams: {
                return_url: 'http://localhost:3000/'
              }
            }).then(function (result) {
              if (result.error) {
                stripeFormSubmit.disabled = false;
                stripeElementsErrors.textContent = result.error.message;
                stripeElementsErrors.style.display = 'block';
              } else {
                stripeTokenHandler(result.paymentIntent);
                stripeElementsErrors.style.display = 'none';
              }
            })
          }
        })
      };
      stripeForm.addEventListener('submit', window.stripeFormSubmit);

      function stripeTokenHandler(paymentIntent) {
        let stripeHiddenInput = document.createElement('input');
        stripeHiddenInput.setAttribute('type', 'hidden');
        stripeHiddenInput.setAttribute('name', 'stripeToken');
        stripeHiddenInput.setAttribute('value', paymentIntent.id);
        stripeForm.appendChild(stripeHiddenInput);
        stripeForm.submit();
      }
    }
  }
}
