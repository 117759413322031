import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    jobName: String,
    pageUrl: String,
    user: Object
  }

  openChat() {
    if (Object.keys(this.userValue).length != 0) {
      var other = new Talk.User({
        id: this.userValue.get_talkjs_user_id,
        name: this.userValue.get_name,
        email: this.userValue.email,
        photoUrl: this.userValue.get_avatar_url,
        role: this.userValue.role,
        custom: {
          companyName: this.userValue.get_company_name
        }
      });
      var conversation = talkSession.getOrCreateConversation(
        Talk.oneOnOneId(talkSession.me, other)
      );
      conversation.setParticipant(talkSession.me);
      conversation.setParticipant(other);
      conversation.setAttributes({
        "custom": {
          "url": this.pageUrlValue
        },
        "subject": this.jobNameValue
      });
      var inbox = talkSession.getInboxes()[0]
      inbox.select(conversation)
      document.getElementById('modal-talkjs').style.display = "block";
      document.getElementById('body').classList.add('js-modaled');
    }
  }
}
