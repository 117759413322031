document.addEventListener('turbo:load', function(e) {

  /* --------------------------------------------------
                Slider Init
  -------------------------------------------------- */
  const cmpSliderInit = function(){
    let backs = document.querySelectorAll('[data-js="slider-back"]');
    let forwards = document.querySelectorAll('[data-js="slider-forward"]');

    Array.prototype.forEach.call(forwards, function(el, i) {
      el.addEventListener('click', (e) => { sliderAdvance(el, e); });
    });

    Array.prototype.forEach.call(backs, function(el, i) {
      el.addEventListener('click', (e) => { sliderAdvance(el, e); });
    });

    const sliderAdvance = function(el, e) {
      let parentSliderContainer = e.target.closest('[data-js="slider-container"]');
      let parentSlides = e.target.closest('[data-js="slider-slides"]');
      let parentSlide = e.target.closest('[data-js="slider-slides-item"]');
      let nextSlide = parentSlide.nextElementSibling;
      let previousSlide = parentSlide.previousElementSibling;
      let sliderStatusMask = parentSliderContainer.querySelector('[data-js="slider-status-mask"]');
      let sliderStatusIndex = [...parentSlides.children].indexOf(e.target.dataset.js == 'slider-forward' ? nextSlide : previousSlide);

      parentSlide.style.display = "none";
      sliderStatusMask.style.width = Math.floor((sliderStatusIndex / parentSlides.children.length) * 100).toString() + '%';
      if (e.target.dataset.js == 'slider-forward') {
        nextSlide.style.display = "block";
      } else {
        previousSlide.style.display = "block";
      }
    }
  }

  /* --------------------------------------------------
                Functions
  -------------------------------------------------- */
  cmpSliderInit();
});
