document.addEventListener('turbo:load', function(e) {

  /* --------------------------------------------------
                Init
  -------------------------------------------------- */
  const cmpConfettiInit = function(){
    var defaultColors=["#a864fd","#29cdff","#78ff44","#ff718d","#fdff6a"],shape=function(t){var e=[function(t){var e=Math.round(10*(Math.random()+.5))+"px";return t.style.width=e,t.style.height=e,t}];return e[Math.floor(Math.random()*e.length)](t)};function createElements(t,e,n){return Array.from({length:e}).map(function(e,a){var i=document.createElement("div"),o=n[a%n.length];return i.style["background-color"]=o,i.style.position="absolute",t.appendChild(shape(i)),i})}function randomPhysics(t,e,n,a){var i=t*(Math.PI/180),o=e*(Math.PI/180);return{x:0,y:0,wobble:10*a(),velocity:.5*n+a()*n,angle2D:-i+(.5*o-a()*o),angle3D:-Math.PI/4+a()*(Math.PI/2),tiltAngle:a()*Math.PI}}function updateFetti(t,e,n){t.physics.x+=Math.cos(t.physics.angle2D)*t.physics.velocity,t.physics.y+=Math.sin(t.physics.angle2D)*t.physics.velocity,t.physics.z+=Math.sin(t.physics.angle3D)*t.physics.velocity,t.physics.wobble+=.1,t.physics.velocity*=n,t.physics.y+=3,t.physics.tiltAngle+=.1;var a=t.physics,i=a.x,o=a.y,s=a.tiltAngle,r=a.wobble,l="translate3d("+(i+10*Math.cos(r))+"px, "+(o+10*Math.sin(r))+"px, 0) rotate3d(1, 1, 1, "+s+"rad)";t.element.style.transform=l,t.element.style.opacity=1-e}function animate(t,e,n){var a=200,i=0;requestAnimationFrame(function o(){e.forEach(function(t){return updateFetti(t,i/a,n)}),(i+=1)<a?requestAnimationFrame(o):e.forEach(function(e){if(e.element.parentNode===t)return t.removeChild(e.element)})})}window.confetti=function(t){var e=arguments.length>1&&void 0!==arguments[1]?arguments[1]:{},n=e.angle,a=void 0===n?90:n,i=e.decay,o=void 0===i?.9:i,s=e.spread,r=void 0===s?45:s,l=e.startVelocity,c=void 0===l?45:l,h=e.elementCount,d=void 0===h?50:h,y=e.colors,f=void 0===y?defaultColors:y,u=e.random,p=void 0===u?Math.random:u;animate(t,createElements(t,d,f).map(function(t){return{element:t,physics:randomPhysics(a,r,c,p)}}),o)};
  }

  /* --------------------------------------------------
                Start Init
  -------------------------------------------------- */
  const cmpConfettiStartInit = function(){
    let confettis = document.querySelectorAll('[data-js="confetti"]');

    Array.prototype.forEach.call(confettis, function(el, i) {
      setTimeout(function() { confetti(el, { angle:90, spread:150, startVelocity:40, elementCount:100 }); }, 100);
    });
  }

  /* --------------------------------------------------
                Functions
  -------------------------------------------------- */
  cmpConfettiInit();
  cmpConfettiStartInit();
});
