import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "input", "clear" ]

  connect() {
    this.showHideClearLink()
  }

  showHideClearLink() {
    if (this.inputTarget.value == '') {
      this.clearTarget.classList.add('tw-hidden')
    } else {
      this.clearTarget.classList.remove('tw-hidden')
    }
  }
}
