document.addEventListener('turbo:load', function(e) {

  /* --------------------------------------------------
                Note
  -------------------------------------------------- */
  pgNotesInit = function(){
    let note = '[data-js="note"]';
    let noteEdits = document.querySelectorAll('[data-js="note-edit"]');
    let noteDetailsFormCancels = document.querySelectorAll('[data-js="note-cancel"]');

    Array.prototype.forEach.call(noteEdits, function(el, i) {
      el.addEventListener('click', (e) => {
        el.closest(note).classList.add('js-editing');
        e.preventDefault();
      });
    });
    Array.prototype.forEach.call(noteDetailsFormCancels, function(el, i) {
      el.addEventListener('click', (e) => {
        el.closest(note).classList.remove('js-editing');
        e.preventDefault();
      });
    });
  }

  /* --------------------------------------------------
                Functions
  -------------------------------------------------- */
  pgNotesInit();
});
