document.addEventListener('turbo:load', function(e) {

  /* --------------------------------------------------
                Checkbox Toggle Init
  -------------------------------------------------- */
  const cmpInputCheckboxToggleInit = function(){
    let toggles = document.querySelectorAll('[data-js="input-checkbox-toggle"]');

    Array.prototype.forEach.call(toggles, function(el, i) {
      el.addEventListener('click', (e) => {
        let togglees = document.querySelectorAll('[data-input-checkbox-togglee="' + e.target.id + '"]');

        Array.prototype.forEach.call(togglees, function(el, i) {
          if (el.style.display === "none") {
            el.style.display = 'block';
          } else {
            el.style.display = 'none';
          }
        });
      });
    });
  }

  /* --------------------------------------------------
                Radio Switch Init
  -------------------------------------------------- */
  const cmpInputRadioSwitchInit = function(){
    let switches = document.querySelectorAll('[data-js="input-radio-switch"]');

    Array.prototype.forEach.call(switches, function(el, i) {
      el.addEventListener('click', (e) => {
        let switchee = document.getElementById('input-radio-switchee-' + el.name + "-" + el.value);
        let switchees = document.querySelectorAll('[id^="input-radio-switchee-' + el.name + '"]');

        Array.prototype.forEach.call(switchees, function(el, i) {
          el.style.display = "none";
        });
        if (switchee) { switchee.style.display = "block"; }
      });
    });
  }

  /* --------------------------------------------------
                Phone Numbers
  -------------------------------------------------- */
  const cmpInputAutoFormatPhoneNumbers = function(){
    let phoneNumberFields = document.querySelectorAll("input[type=tel][pattern]")
    Array.prototype.forEach.call(phoneNumberFields, function(el, i) {
      el.addEventListener('keydown', (e) => {
        const formattedInputValue = formatPhoneNumber(el.value);
        el.value = formattedInputValue;
      });
    });
  }

  const formatPhoneNumber = function(value) {
    if (!value) return value;
    const phoneNumber = value.replace(/[^\d]/g, '');
    const phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength < 4) return phoneNumber;
    if (phoneNumberLength < 7) {
      return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3)}`;
    }
    return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(
      3,
      6
    )}-${phoneNumber.slice(6, 10)}`;
  }

  /* --------------------------------------------------
                Functions
  -------------------------------------------------- */
  cmpInputCheckboxToggleInit();
  cmpInputRadioSwitchInit();
  cmpInputAutoFormatPhoneNumbers()
});
