document.addEventListener('turbo:load', function(e) {

  /* --------------------------------------------------
                Update
  -------------------------------------------------- */
  pgNotesUpdate = function(id, partial){
    let note = document.getElementById('note-' + id);

    note.insertAdjacentHTML('beforebegin', unescape(partial));
    note.remove();
    pgNotesInit();
  }
});
