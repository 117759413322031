import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "background" ]

  connect() {
    if (!!this.data.get("param") && !!this.data.get("paramId")) {
      const newLocation = `${window.location.pathname}?${this.data.get("param")}=${this.data.get("paramId")}`
      history.pushState({ urlPath: newLocation }, "", newLocation)
    }
  }

  close() {
    // Remove the modal element so it doesn't blanket the screen
    this.element.remove()
  }

  closeBackground(e) {
    if (e.target === this.backgroundTarget) {
      this.close(e)
    }
  }

  handleKeyup(e) {
    if (e.code == "Escape") {
      this.close()
    }
  }
}
