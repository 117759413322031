import flatpickr from 'flatpickr';
document.addEventListener('turbo:load', function(e) {

  /* --------------------------------------------------
                Init
  -------------------------------------------------- */
  const vdFlatpickrInit = function(){
    flatpickr('[data-js="flatpickr"]', {
      enableTime: true,
      dateFormat: 'Y-m-d H:i:S',
      altInput: true,
      altFormat: 'M J, Y at h:iK',
      allowInput: true });
  }

  /* --------------------------------------------------
                Functions
  -------------------------------------------------- */
  vdFlatpickrInit();
});
