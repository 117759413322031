document.addEventListener('turbo:load', function(e) {

  /* --------------------------------------------------
                Init
  -------------------------------------------------- */
  const cmpNavInit = function(){
    let body = document.getElementsByTagName("body")[0];
    let navMobile = document.getElementById('nav-mobile');
    let navMobileHamburger = document.getElementById('nav-mobile-hamburger');
    let navMobileItemsClose = document.getElementById('nav-mobile-items-close');

    if (navMobileHamburger){
      navMobileHamburger.addEventListener('click', (e) => {
        body.classList.add('js-locked');
        navMobile.classList.add('js-active');
      });

      navMobileItemsClose.addEventListener('click', (e) => {
        body.classList.remove('js-locked');
        navMobile.classList.remove('js-active');
      });
    }
  }

  /* --------------------------------------------------
                Functions
  -------------------------------------------------- */
  cmpNavInit();
});
