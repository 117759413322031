['turbo:frame-load', 'turbo:load'].forEach(function(ev) {
  document.addEventListener(ev, function(e) {
    /* --------------------------------------------------
                  Init
    -------------------------------------------------- */
    const cmpModalInit = function(){
      let modalOpens = document.querySelectorAll('[data-js="modal-open"]');
      let modalCloses = document.querySelectorAll('[data-js="modal-close"]');
      let modalContainer = document.querySelector('[data-js="modal-container"]');
      let modalScroll = document.querySelector('[data-js="modal-scroll"]');
      let modalContextOpenOnLoads = document.querySelectorAll('[data-context="modal-openonload"]');

      Array.prototype.forEach.call(modalOpens, function(el, i) {
        el.addEventListener('click', (e) => {
          document.getElementById('body').classList.add('js-modaled');
          if (!!e.target.dataset.id) {
            document.getElementById('modal-' + e.target.dataset.id).style.display = "block";
          } else if (e.target.closest('[data-id]')) {
            document.getElementById('modal-' + e.target.closest('[data-id]').dataset.id).style.display = "block";
          }
          e.preventDefault();
        });
      });

      Array.prototype.forEach.call(modalCloses, function(el, i) {
        el.addEventListener('click', (e) => {
          e.target.closest('[data-js="modal-container"]').style.display = "none";
          document.getElementById('body').classList.remove('js-modaled');
          e.preventDefault();
        });
      });

      // We don't want to add the event listener on turbo:frame-loads as it already exists
      if (modalContainer != null) {
        document.addEventListener('click', (e) => {
          if (document.getElementById('body').classList.contains('js-modaled')) {
            if (e.target.className == modalContainer.className || e.target.className == modalScroll.className) {
              document.getElementById('body').classList.remove('js-modaled');
              e.target.closest('.' + modalContainer.className).style.display = "none";
              e.preventDefault();
            }
          }
        });
      }

      if (modalContextOpenOnLoads.length){
        document.getElementById('body').classList.add('js-modaled');
      }
    }

    /* --------------------------------------------------
                  Functions
    -------------------------------------------------- */
    cmpModalInit();
  });
})
