import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import listPlugin from '@fullcalendar/list';
import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';
import '@fullcalendar/list/main.css';
import {delegate} from 'tippy.js';

document.addEventListener('turbo:load', function(e) {

  /* --------------------------------------------------
                Full Calendar Init
  -------------------------------------------------- */
  const pgCalendarFullCalendarInit = function(){
    let calendarId = 'pages-calendar-fullcalendar';
    let calendarItem = '.fc-day-grid-event';
    let calendarEl = document.getElementById(calendarId);

    if (calendarEl) {
      let calendar = new Calendar(calendarEl, {
        timeZone: window.planRoom.timeZone,
        plugins: [dayGridPlugin, listPlugin],
        header: { right: 'dayGridMonth,listMonth today prev,next' },
        height: 'parent',
        weekends: false,
        buttonText: {
          today: 'Today',
          dayGridMonth: 'Month',
          listMonth: 'List' },
        eventLimit: true,
        eventSources: [{
          url: '/pages/calendar/jobs'
        }],
        displayEventTime: false
      });
      calendar.render();
      delegate('#' + calendarId, {
        target: calendarItem,
        content: (element) => element.textContent
      });
    }
  }

  /* --------------------------------------------------
                Functions
  -------------------------------------------------- */
  pgCalendarFullCalendarInit();
});
