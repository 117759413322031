import * as filestack from 'filestack-js';
const client = filestack.init('AmXHoC0fuTbSNI27c9ezKz');
document.addEventListener('DOMContentLoaded', function(e) {

  /* --------------------------------------------------
                Upload Init
  -------------------------------------------------- */
  const vdFilestackUploadInit = function(){
    let filestackerInputs = 'filestack';

    document.addEventListener('change', (e) => {
      if (e.target.dataset.js == filestackerInputs) {
        let inputFiles = e.target.files;
        let inputFile = inputFiles.item(0);
        let onProgress = (evt) => { e.target.previousElementSibling.innerHTML = `Uploading ${evt.totalPercent}%`; };

        // Upload
        client.upload(inputFile, { onProgress }, {}, {})
          .then(res => {
            e.target.value = '';
            e.target.previousElementSibling.previousElementSibling.innerHTML = "Replace";
            e.target.previousElementSibling.innerHTML = res.filename;
            e.target.previousElementSibling.setAttribute('href', res.url);
            e.target.nextElementSibling.value = res.url;
            e.target.nextElementSibling.nextElementSibling.value = res.filename;
            e.target.nextElementSibling.nextElementSibling.nextElementSibling.value = res.handle;
          })
          .catch(err => {});
      }
    });
  }

  /* --------------------------------------------------
                Upload Multiple Init
  -------------------------------------------------- */
  const vdFilestackUploadMultipleInit = function(){
    const filestackerMultipleInputs = 'filestack-multiple-input';
    const dropzoneMaskQueryString = '[data-js="filestack-dropzone-mask"]';
    const dropzoneQueryString = '[data-filestack-dropzone="true"]';

    // Dropzone handlers
    const isDropzoned = function(){
      var div = document.createElement('div');
      return (('draggable' in div) || ('ondragstart' in div && 'ondrop' in div)) && 'FormData' in window && 'FileReader' in window;
    }();
    const dropHandler = function(dropzoneMask, e){
      e.preventDefault();
      dropzoneMask.style.display = 'none';
      uploadFiles(e);
    };
    const dragEnterHandler = function(dropzoneMask, e){
      e.preventDefault();
      dropzoneMask.style.display = 'flex';
    };
    const dragLeaveHandler = function(dropzoneMask, e){
      e.preventDefault();
      dropzoneMask.style.display = 'none';
    };

    // Dropzone setup
    if (isDropzoned) {
      let dropzones = document.querySelectorAll(dropzoneQueryString);
      Array.prototype.forEach.call(dropzones, function(dropzone) {
        let dropzoneMask = dropzone.querySelector(dropzoneMaskQueryString);
        dropzone.addEventListener('dragover', dragEnterHandler.bind(null, dropzoneMask), false);
        dropzoneMask.addEventListener('dragleave', dragLeaveHandler.bind(null, dropzoneMask), false);
        dropzoneMask.addEventListener('dragdrop', dropHandler.bind(null, dropzoneMask), false);
        dropzoneMask.addEventListener('drop', dropHandler.bind(null, dropzoneMask), false);
      })
    }

    // Upload files
    const uploadFiles = function(e){
      let inputFiles = e.target.files || e.dataTransfer.files;
      let inputPrefix = e.target.dataset.prefix;
      let token = {};
      let container = e.target.closest(dropzoneQueryString);
      let multipleElement = container.querySelector('[data-js="filestack-multiple"]');
      let multipleElementProgress = multipleElement.querySelector('[data-js="filestack-multiple-progress"]');
      let multipleElementControlsStatus = multipleElement.querySelector('[data-js="filestack-multiple-controls-status"]');
      let multipleElementControlsCancel = multipleElement.querySelector('[data-js="filestack-multiple-controls-cancel"]');

      // Cancel
      multipleElementControlsCancel.addEventListener('click', () => {
        token['cancel']();
        multipleElement.classList.remove('js-uploading');
      })

      // Progress
      let onProgress = (evt) => {
        multipleElement.classList.add('js-uploading');
        multipleElementProgress.style.width = evt.totalPercent + '%';
        multipleElementControlsStatus.innerHTML = 'Uploading ' + inputFiles.length + ' files: ' + evt.totalPercent + '%';
      };

      // Upload
      client.multiupload(inputFiles, { onProgress }, {}, token)
        .then(res => {
          multipleElement.classList.remove('js-uploading');
          Array.prototype.forEach.call(res, function(result) {
            if (result.status == "Stored") {
              let data = {}
              data[`${inputPrefix}__attachment_url`] =  result.url;
              data[`${inputPrefix}__attachment_filename`] = result.filename;
              data[`${inputPrefix}__attachment_handle`] = result.handle;

              // Add field
              let fieldNew = window.fieldAddDuplicatedNodeToPage(multipleElement, data);
              let fieldLink = fieldNew.getElementsByTagName('a')[0];
              fieldLink.textContent = result.filename;
              fieldLink.href = result.url;

              // Detect category
              let categorySelect = fieldNew.getElementsByTagName('select')[0];
              if (categorySelect != undefined) {
                let categoryFoundIndex = -1;
                Array.prototype.forEach.call(categorySelect.options, function(option, i) {
                  if (result.filename.replace(new RegExp(' ', 'g'), '_').toLowerCase().indexOf(option.value) != -1) {
                    categoryFoundIndex = i;
                  }
                })
                if (categoryFoundIndex != -1) {
                  categorySelect.value = categorySelect.options[categoryFoundIndex].value;
                }
              }
            }
          });
          e.target.value = ''
        })
        .catch(err => {
          console.log(err);
        });
      }

    // Trigger upload
    document.addEventListener('change', (e) => {
      if (e.target.dataset.js == filestackerMultipleInputs) {
        uploadFiles(e)
      }
    })
  }

  /* --------------------------------------------------
                Functions
  -------------------------------------------------- */
  vdFilestackUploadInit();
  vdFilestackUploadMultipleInit();
});

document.addEventListener('turbo:load', function(e) {
  /* --------------------------------------------------
                Picker Avatar Init
  -------------------------------------------------- */
  const vdFilestackPickerAvatarPInit = function(){
    let filestackers = document.querySelectorAll('[data-js="filestack-picker-avatar"]');

    Array.prototype.forEach.call(filestackers, function(el, i) {
      el.addEventListener('click', (e) => {
        client.picker({
          onUploadDone: function(result){
            el.querySelector('img').setAttribute('src', result.filesUploaded[0].url);
            el.nextElementSibling.value = result.filesUploaded[0].url;
            el.nextElementSibling.nextElementSibling.value = result.filesUploaded[0].filename;
            el.nextElementSibling.nextElementSibling.nextElementSibling.value = result.filesUploaded[0].handle;
          },
          maxSize: 10 * 1024 * 1024,
          accept: 'image/*',
          uploadInBackground: false,
          fromSources: ["local_file_system"],
          transformations: {
            circle: true,
            force: true
          }
        }).open();
        e.preventDefault();
      });
    });
  }

  /* --------------------------------------------------
                Functions
  -------------------------------------------------- */
  vdFilestackPickerAvatarPInit();
});
