document.addEventListener('turbo:load', function(e) {

  /* --------------------------------------------------
                Jumper Init
  -------------------------------------------------- */
  const cmpSelectJumperInit = function(){
    let jumpers = document.querySelectorAll('[data-js="select-jumper"]');

    Array.prototype.forEach.call(jumpers, function(el, i) {
      el.addEventListener('change', (e) => {
        var url = new URL(window.location);
        if (el.dataset.hostonly) {
          url = new URL(e.target.value);
        } else {
          var searchParams = e.target.value.split('=');
          url.searchParams.set(searchParams[0], searchParams[1]);
        }
        window.location = url;
      });
    });
  }

  /* --------------------------------------------------
                Switch init
  -------------------------------------------------- */
  const cmpSelectSwitchInit = function(){
    let switches = document.querySelectorAll('[data-js="select-switch"]');

    Array.prototype.forEach.call(switches, function(el, i) {
      el.addEventListener('change', (e) => {
        let switchee = document.getElementById('select-switchee-' + el.name + "-" + el.value);
        let switchees = document.querySelectorAll('[id^="select-switchee-' + el.name + '"]');

        Array.prototype.forEach.call(switchees, function(el, i) {
          el.style.display = "none";
        });
        if (switchee) { switchee.style.display = "block"; }
      });
    });
  }

  /* --------------------------------------------------
                Functions
  -------------------------------------------------- */
  cmpSelectJumperInit();
  cmpSelectSwitchInit();
});
