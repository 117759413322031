document.addEventListener('turbo:load', function(e) {

  /* --------------------------------------------------
                Get Location
  -------------------------------------------------- */
  pgJobsFormGetLocationInit = function(address, id){
    let locationInput = document.getElementById('job_location');
    let locationInputLat = document.getElementById('job_location_lat');
    let locationInputLng = document.getElementById('job_location_lng');

    if (locationInput) {
      locationInput.addEventListener("focusout", function(){
        new google.maps.Geocoder().geocode({ 'address': locationInput.value }, function(results, status) {
          if (status === 'OK') {
            locationInputLat.value = results[0].geometry.location.lat();
            locationInputLng.value = results[0].geometry.location.lng();
          } else {
            alert('Woops that address could not be found in Google Maps. Please double check spelling and try again.')
          }
        });
      });
    }
  }

  /* --------------------------------------------------
                Functions
  -------------------------------------------------- */
  pgJobsFormGetLocationInit();
});
