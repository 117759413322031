let Tabulator = require('tabulator-tables');
document.addEventListener('turbo:load', function(e) {

  /* --------------------------------------------------
                Init
  -------------------------------------------------- */
  const vdTabulatorInit = function(){
    //new Tabulator('[data-js="tabulator"]');
  }

  /* --------------------------------------------------
                Functions
  -------------------------------------------------- */
  vdTabulatorInit();
});
