import Choices from 'choices.js';
['turbo:frame-load', 'turbo:load'].forEach(function(ev) {
  document.addEventListener(ev, function(e) {

    /* --------------------------------------------------
                  Init
    -------------------------------------------------- */
    const vdChoicesInit = function(){
      let choices = document.querySelectorAll('[data-js="choices"]');
      let choiceSwitchees = document.querySelectorAll('[data-js="choice-switchee"]');

      Array.prototype.forEach.call(choices, function(el, i) {
        let choice = new Choices(el, {
          searchPlaceholderValue: "Search",
          removeItemButton: el.hasAttribute('data-choices-remove-item-button') ? true : false,
          searchEnabled: el.hasAttribute('data-choices-search-enabled') ? true : false,
          classNames: { containerInner: 'cmp-input choices__inner' }
        });

        choice.passedElement.element.addEventListener('choice',
          function(e) {
            if (el.hasAttribute('data-choices-switch')){
              let switchee = document.getElementById('choice-switchee-' + e.target.name + "-" + e.detail.choice.value);
              let switchees = document.querySelectorAll('[id^="choice-switchee-' + e.target.name + '"]');
              Array.prototype.forEach.call(switchees, function(el, i) {
                el.style.display = "none";
              });
              if (switchee) { switchee.style.display = "block"; }
            }
          },
        false);
      });
    }

    /* --------------------------------------------------
                  Jumper Init
    -------------------------------------------------- */
    const vdChoicesJumperInit = function(){
      let jumpers = document.querySelectorAll('[data-choices-jumper="true"]');

      Array.prototype.forEach.call(jumpers, function(el, i) {
        el.addEventListener('choice', (e) => {
          var searchParams = e.detail.choice.value.split('=');
          var url = new URL(window.location);
          url.searchParams.set(searchParams[0], searchParams[1]);
          window.location = url;
        });
      });
    }

    /* --------------------------------------------------
                  Functions
    -------------------------------------------------- */
    vdChoicesInit();
    vdChoicesJumperInit();
  });
})
