['turbo:frame-load', 'turbo:load'].forEach(function(ev) {
  document.addEventListener(ev, function(e) {

    /* --------------------------------------------------
                  Toggle Init
    -------------------------------------------------- */
    const pgJobTransactionsNewToggleInit = function(){
      let newContainer = document.getElementById('pages-job_transactions-new-container');

      if (newContainer) {
        let newOpen = document.getElementById('pages-job_transactions-new-open');
        let newActiveClass = 'js-pg-job_transactions-shared-new-active';

        newOpen.addEventListener('click', (e) => {
          document.querySelector('body').classList.add(newActiveClass);
          e.preventDefault();
        });

        document.addEventListener('click', (e) => {
          if (document.getElementById('body').classList.contains(newActiveClass)) {
            if (e.target == newContainer) {
              document.getElementById('body').classList.remove(newActiveClass);
              e.preventDefault();
            }
          }
        });
      }
    }

    /* --------------------------------------------------
                  Stripe Init
    -------------------------------------------------- */
    const pgJobTransactionsNewStripeInit = function(){
      let form = document.getElementById('new_job_transaction');

      if (form) {
        let formSubmit = form.querySelector('button[type="submit"]');
        let checkboxDeliveryCategories = document.querySelectorAll('[name="job_transaction[delivery_categories][]"]');
        let checkboxDeliveryCategoriesChecked = '[name="job_transaction[delivery_categories][]"]:checked';
        let checkboxDigitalCategoryCharge = document.getElementById('job_transaction_delivery_categories_digital_category_charge');
        let checkboxPrintCategoryCharge = document.getElementById('job_transaction_delivery_categories_print_category_charge');
        let checkboxPrintCategoryDeposit = document.getElementById('job_transaction_delivery_categories_print_category_deposit');
        let checkboxJobContracts =  document.querySelectorAll('[name="job_transaction[job_transaction_job_contracts_attributes][0][job_contract][]"]');
        let checkboxJobContractsChecked = '[name="job_transaction[job_transaction_job_contracts_attributes][0][job_contract][]"]:checked';
        let sectionCategoryCharge = document.getElementById('job_transaction_delivery_category_charge');
        let sectionCategoryDeposit = document.getElementById('job_transaction_delivery_category_deposit');
        let sectionPrintCategoryDeposit = document.getElementById('job_transaction_delivery_print_category_deposit');
        let sectionPrintCategoryShipping = document.getElementById('job_transaction_delivery_print_category_charge');

        form.removeEventListener('submit', window.stripeFormSubmit);
        Array.prototype.forEach.call(checkboxDeliveryCategories, function(el, i) {
           el.addEventListener('click', (e) => {
             toggleFormEventListener(e);
             toggleFormSubmit();
           });
        });
        Array.prototype.forEach.call(checkboxJobContracts, function(el, i) {
           el.addEventListener('click', (e) => {
             toggleFormSubmit();
           });
        });

        const toggleFormEventListener = function(e){
          if (sectionCategoryCharge) {
            if ((checkboxDigitalCategoryCharge && checkboxDigitalCategoryCharge.checked) || (checkboxPrintCategoryCharge && checkboxPrintCategoryCharge.checked)) {
              sectionCategoryCharge.style.display = 'block';
              form.addEventListener('submit', window.stripeFormSubmit);
            } else if ((checkboxDigitalCategoryCharge && !checkboxDigitalCategoryCharge.checked) || (checkboxPrintCategoryCharge && !checkboxPrintCategoryCharge.checked)) {
              sectionCategoryCharge.style.display = 'none';
              form.removeEventListener('submit', window.stripeFormSubmit);
            }
            if (checkboxPrintCategoryCharge && checkboxPrintCategoryCharge.checked) {
              sectionPrintCategoryShipping.style.display = 'block';
            }
            if (checkboxPrintCategoryCharge && !checkboxPrintCategoryCharge.checked) {
              sectionPrintCategoryShipping.style.display = 'none';
            }
          }
          if (sectionCategoryDeposit) {
            if (checkboxPrintCategoryDeposit && checkboxPrintCategoryDeposit.checked) {
              sectionCategoryDeposit.style.display = 'block';
              sectionPrintCategoryDeposit.style.display = 'block';
            } else {
              sectionCategoryDeposit.style.display = 'none';
              sectionPrintCategoryDeposit.style.display = 'none';
            }
          }
        }
        const toggleFormSubmit = function(){
          if (document.querySelectorAll(checkboxDeliveryCategoriesChecked).length > 0 &&
              document.querySelectorAll(checkboxJobContractsChecked).length > 0) {
            formSubmit.disabled = false;
          } else {
            formSubmit.disabled = true;
          }
        }
      }
    }

    /* --------------------------------------------------
                  Functions
    -------------------------------------------------- */
    pgJobTransactionsNewToggleInit();
    pgJobTransactionsNewStripeInit();
  });
})
