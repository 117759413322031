document.addEventListener('turbo:load', function(e) {

  /* --------------------------------------------------
                Init
  -------------------------------------------------- */
  const vdTrixInit = function(){

    // Turn off attachments
    document.addEventListener("trix-file-accept", function(event) {
      event.preventDefault();
    });
  }

  /* --------------------------------------------------
                Functions
  -------------------------------------------------- */
  vdTrixInit();
});
