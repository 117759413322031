document.addEventListener('turbo:load', function(e) {

  /* --------------------------------------------------
                Init
  -------------------------------------------------- */
  const cmpTabsInit = function(){
    let tabs = document.querySelectorAll('[data-js="tabs-item"]');
    let tabsPanels = document.querySelectorAll('[data-js="tabs-panel"]');

    Array.prototype.forEach.call(tabs, function(el, i) {
      el.addEventListener('click', (e) => {
        Array.prototype.forEach.call(tabs, function(el, i) {
          el.classList.remove('js-selected');
        });
        Array.prototype.forEach.call(tabsPanels, function(el, i) {
          el.classList.remove('js-selected');
          el.classList.add('tw-hidden');
        });
        e.target.classList.add('js-selected');
        let panel = document.querySelector('[data-panel="' + el.dataset.tabPanel + '"]');
        if (!!panel) {
          panel.classList.add('js-selected');
          panel.classList.remove('tw-hidden');
        }
      });
    });
  }

  const nextTabButtonsInit = function(){
    let nextTabButtons = document.querySelectorAll('[data-js="tab-next"]');

    Array.prototype.forEach.call(nextTabButtons, function(el, i) {
      el.addEventListener('click', (e) => {
        e.preventDefault();
        selectedTab = document.querySelector('.cmp-tabs-item.js-selected')

        if (!!selectedTab) {
          nextTab = selectedTab.nextElementSibling
          if (!!nextTab) {
            nextTab.click()
            var scrollPos = nextTab.offsetTop;
            window.scrollTo({ top: scrollPos, behavior: 'smooth'});
          }
        }
      });
    });
  }

  /* --------------------------------------------------
                Functions
  -------------------------------------------------- */
  cmpTabsInit();
  nextTabButtonsInit();
});
