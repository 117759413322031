document.addEventListener('turbo:load', function(e) {

  /* --------------------------------------------------
                Init
  -------------------------------------------------- */
  const cmpBoxCollapseInit = function(){
    let boxCollapsers = document.querySelectorAll('[data-js="box-collapse"]');

    Array.prototype.forEach.call(boxCollapsers, function(el, i) {
      el.addEventListener('click', (e) => {
        el.parentElement.classList.contains('js-collapsed') ? el.parentElement.classList.remove('js-collapsed') : el.parentElement.classList.add('js-collapsed');
      });
    });
  }

  /* --------------------------------------------------
                Collapse Param Init
  -------------------------------------------------- */
  const cmpBoxCollapseParamInit = function () {
    let urlSearchParams = new URLSearchParams(window.location.search);
    let collapseParam = urlSearchParams.get('collapse');
    if (collapseParam) {
      let targetDiv = document.getElementById('collapse-'+ collapseParam);
      if (targetDiv) {
        targetDiv.classList.remove('js-collapsed');
        window.scrollTo({ top: targetDiv.getBoundingClientRect().top + window.pageYOffset + -(navHeight), behavior: 'smooth' });
      }
    }
  };

  /* --------------------------------------------------
                Next Buttons Init
  -------------------------------------------------- */
  const cmpBoxNextButtonsInit = function(){
    let nextBoxButtons = document.querySelectorAll('[data-js="box-next"]');

    Array.prototype.forEach.call(nextBoxButtons, function(el, i) {
      el.addEventListener('click', (e) => {
        e.preventDefault();

        var box = e.target.closest('.cmp-box')
        if (!!box) {
          nextBox = box.nextElementSibling
          if (!!nextBox) {
            nextBox.classList.remove('js-collapsed');
            var scrollPos = nextBox.offsetTop;
            window.scrollTo({ top: scrollPos, behavior: 'smooth'});
          }
        }
      });
    });
  }

  /* --------------------------------------------------
                Functions
  -------------------------------------------------- */
  cmpBoxCollapseInit();
  cmpBoxNextButtonsInit();
  cmpBoxCollapseParamInit();
});
