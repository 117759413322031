import tippy from 'tippy.js';
import {delegate} from 'tippy.js';

['turbo:frame-load', 'turbo:load'].forEach(function(ev) {
  document.addEventListener(ev, function(e) {

    /* --------------------------------------------------
                  Init
    -------------------------------------------------- */
    const vdTippyInit = function(){
      tippy('[data-js="tippy"]');
    }

    /* --------------------------------------------------
                  Functions
    -------------------------------------------------- */
    vdTippyInit();
  });
})
