document.addEventListener('turbo:load', function(e) {

  /* --------------------------------------------------
                Job Change Init
  -------------------------------------------------- */
  pgJobChangesInit = function(){
    let jobChangeForm = '.edit_job_change';
    let jobChangeCompletes = document.querySelectorAll('[name="job_change[complete]"]');

    Array.prototype.forEach.call(jobChangeCompletes, function(el, i) {
      el.addEventListener('click', (e) => {
        var form = e.target.closest(jobChangeForm);
        var xhr = new XMLHttpRequest();

        xhr.onload = () => { eval(xhr.responseText); }
        xhr.open (form.method, form.action, true);
        xhr.send (new FormData (form));
        return false;
      });
    });
  }

  /* --------------------------------------------------
                Functions
  -------------------------------------------------- */
  pgJobChangesInit();
});
