document.addEventListener('turbo:load', function(e) {

  /* --------------------------------------------------
                Update
  -------------------------------------------------- */
  pgJobChangesUpdate = function(id, partial){
    let jobChange = document.getElementById('job_change-' + id);

    jobChange.insertAdjacentHTML('beforebegin', unescape(partial));
    jobChange.remove();
    pgJobChangesInit();
  }
});
