document.addEventListener('turbo:load', function(e) {

  /* --------------------------------------------------
                Init
  -------------------------------------------------- */
  const cmpStickyInit = function(){
    let stickies = document.querySelectorAll('[data-js="sticky"]');
    if (stickies) {
      Array.prototype.forEach.call(stickies, function(el, i) {
        var observer = new IntersectionObserver(function(entries) {
        	if (entries[0].intersectionRatio === 0) {
        		el.classList.add("js-sticky");
          } else if (entries[0].intersectionRatio === 1) {
        		el.classList.remove("js-sticky");
          }
        }, { threshold: [0,1], rootMargin: '-' + el.offsetHeight + 'px' });
        observer.observe(el.previousElementSibling);
      });
    }
  }

  /* --------------------------------------------------
                Functions
  -------------------------------------------------- */
  cmpStickyInit();
});
