import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "notificationIndicator"
  ]
  static values = {
    appId: String,
    signature: String,
    user: Object
  }

  connect() {
    (function(t,a,l,k,j,s){
      s=a.createElement('script');s.async=1;s.src="https://cdn.talkjs.com/talk.js";a.head.appendChild(s)
      ;k=t.Promise;t.Talk={v:3,ready:{then:function(f){if(k)return new k(function(r,e){l.push([f,r,e])});l
      .push([f])},catch:function(){return k&&new k()},c:l}};})(window,document,[]);

    Talk.ready.then(() => {
      if (Object.keys(this.userValue).length != 0) {
        var me = new Talk.User({
          id: this.userValue.get_talkjs_user_id,
          name: this.userValue.get_name,
          email: this.userValue.email,
          photoUrl: this.userValue.get_avatar_url,
          role: this.userValue.role,
          custom: {
            companyName: this.userValue.get_company_name
          }
        });

        window.talkSession = new Talk.Session({
          appId: this.appIdValue,
          me: me,
          signature: this.signatureValue
        });

        var inbox = talkSession.createInbox({});
        inbox.mount(document.getElementById('talkjs-container'));

        talkSession.unreads.on("change", this.toggleNotificationIndicator.bind(this))

        const params = new Proxy(new URLSearchParams(window.location.search), {
          get: (searchParams, prop) => searchParams.get(prop),
        });
        if (params.chat == 'open') {
          var conversation = talkSession.getOrCreateConversation(params.conversationId)
          inbox.select(conversation)
          this.element.querySelector('a').click()
        }
      }
    });
  }

  toggleNotificationIndicator(conversationIds) {
    if (conversationIds.length > 0) {
      this.notificationIndicatorTarget.style.display = 'block';
    } else {
      this.notificationIndicatorTarget.style.display = 'none';
    }
  }
}
