import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "banner" ]

  hideUpgradeBanner() {
    fetch('/pages/users/hide_nav_upgrade_banner', { headers: { accept: "application/json" }, method: "POST" })
      .then(() => {
        this.bannerTarget.classList.add('tw-hidden')
      })
  }
}
