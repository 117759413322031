document.addEventListener('turbo:load', function(e) {

  /* --------------------------------------------------
                Duplicatee
  -------------------------------------------------- */
  const cmpFieldDuplicateInit = function(){
    let duplicatee = '[data-js="field-duplicatee"]';
    let duplicateeContainer = '[data-js="field-duplicatee-container"]';
    let duplicateeRemoveBtns = 'field-duplicatee-remove';
    let duplicateeFlatPickr = '[data-js="flatpickr"]';
    let cloneableValues = '[data-clone="true"]';

    const attachDuplicateOnClickEvent = (element) => {
      duplicateeAddBtns = element.querySelectorAll('[data-js="field-duplicatee-add"]');
      Array.prototype.forEach.call(duplicateeAddBtns, function(el, i) {
        el.addEventListener('click', (e) => {
          addDuplicatedNodeToPage(el);
          e.preventDefault();
        });
      });
    }

    const addDuplicatedNodeToPage = (el, cloneableValues) => {
      var fieldNew = document.createElement('div');
      var template = el.nextElementSibling.childNodes[0];
      var templateId = template.dataset.id;
      var templateContent = template.innerHTML;

      fieldNew.innerHTML = templateContent.replace(new RegExp(templateId, 'g'), Math.floor(new Date().valueOf() * Math.random()));
      el.previousElementSibling.append(fieldNew.firstChild);
      if (cloneableValues === undefined) { cloneableValues = getCloneableValues(el) };
      setCloneableValues(el, cloneableValues);
      resetFlatPickrs(el.previousElementSibling);
      toggleLabels(el.previousElementSibling);
      attachDuplicateOnClickEvent(el.previousElementSibling.lastChild);
      return el.previousElementSibling.lastChild;
    }

    const toggleLabels = function(container){
      if (container.hasAttribute('data-hide-labels')) {
        var duplicatees = container.querySelectorAll(duplicatee);
        for (var i=0; i < duplicatees.length; i++) {
          duplicatees[i].classList.remove('js-firstchild');
        }
        for (var i=0; i < duplicatees.length; i++) {
          if (!duplicatees[i].style.display) {
            duplicatees[i].classList.add('js-firstchild');
            break;
          }
        }
      }
    }

    const resetFlatPickrs = function(container){
      Array.prototype.forEach.call(container.lastChild.querySelectorAll(duplicateeFlatPickr), function(el, i) {
        if (el.value == 'undefined') { el.value = '' }
        flatpickr_el = flatpickr(el, { enableTime: true, dateFormat: 'Y-m-d H:i:S', altInput: true, altFormat: 'M J, Y at h:iK', allowInput: true });
      });
    }

    const getCloneableValues = function(element) {
      let data = {}
      var matchingElements = element.parentNode.querySelectorAll(duplicatee);
      if (matchingElements.length > 1) {
        originalElement = matchingElements[matchingElements.length - 2];
        Array.prototype.forEach.call(originalElement.querySelectorAll(cloneableValues), function(el, i) {
          key = el.id.replace(RegExp(/\d+/, 'g'), '');
          data[key] = el.value;
        }, data);
      }
      return data
    }

    const setCloneableValues = function(element, data) {
      var matchingElements = element.parentNode.querySelectorAll(duplicatee);
      if (matchingElements.length >= 1) {
        clone = matchingElements[matchingElements.length - 1];
        Array.prototype.forEach.call(clone.querySelectorAll(cloneableValues), function(el, i) {
          key = el.id.replace(RegExp(/\d+/, 'g'), '');
          el.value = data[key];
          data[key] = el.value
        }, data);
      }
    }

    document.addEventListener('click', (e) => {
      if (e.target.dataset.js == duplicateeRemoveBtns) {
        e.target.previousElementSibling.value = "1";
        e.target.closest(duplicatee).style.display = 'none';
        toggleLabels(e.target.closest(duplicateeContainer));
        e.preventDefault();
      }
    });

    attachDuplicateOnClickEvent(document);
    window.fieldAddDuplicatedNodeToPage = addDuplicatedNodeToPage
  }

  /* --------------------------------------------------
                Functions
  -------------------------------------------------- */
  cmpFieldDuplicateInit();
});
