import PDFJSExpress from '@pdftron/pdfjs-express'
document.addEventListener('turbo:load', function(e) {

  /* --------------------------------------------------
                Init
  -------------------------------------------------- */
  const pgJobFilesInit = function(){
    let pdfjsElement = document.getElementById('pdfjs');

    if (pdfjsElement) {
      WebViewer({
        path: '/pdftron/pdfjsexpress',
        css: '/pdftron/pdfjsexpress/ui/style.css',
        initialDoc: 'https://cdn.filepicker.io/' + pdfjsElement.dataset.handle,
        disabledElements: [
          'printModal',
          'freeHandToolGroupButton',
          'selectToolButton',
          'textToolGroupButton',
          'shapeToolGroupButton',
          'signatureToolButton',
          'freeTextToolButton',
          'stickyToolButton',
          'miscToolGroupButton',
          'toolsButton',
          'searchButton',
          'menuButton',
          'leftPanelButton',
          'eraserToolButton',
          'viewControlsButton',
          'panToolButton',
          'contextMenuPopup'
        ]
      }, pdfjsElement)
      .then(function(instance) {
        pdfjsElement.setAttribute('data-handle', '');
        instance.setHeaderItems(header => {
          const items = header.getItems().slice(2, -9);
          header.update(items);
        });
      });
    }
  }

  /* --------------------------------------------------
                Functions
  -------------------------------------------------- */
  pgJobFilesInit();
});
